import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Icon } from '@mui/material';
import { format } from 'date-fns';

import PlaylistPitchModal from '@/components/campaigns-page/PlaylistPitchModal';
import LineChartComponent from '@/components/charts/LineChart';
import Loading from '@/components/utility/Loading';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import UserHeader from '@/components/utility/navigation/UserHeader';
import CampaignStatus from '@/components/utility/statuses/CampaignStatus';
import PercentageDifference from '@/components/utility/statuses/PercentageDifference';
import PlaylistPitchStatus from '@/components/utility/statuses/PlaylistPitchStatus';
import { Images } from '@/constants/Images';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlaylistTrackStats from '@/hooks/metrics/usePlaylistTrackStats';
import useTrackIdFromSpotifyId from '@/hooks/metrics/useTrackIdfromSpotifyId';
import usePlaylistPitch from '@/hooks/playlist/usePlaylistPitch';
import { PlaylistChartMetricsModel } from '@/models/Metrics';
import { PlaylistPitchAccountItemsPlaylistModel } from '@/models/Playlist';

const CampaignPlaylistPitchPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [playlist, setPlaylist] = useState<PlaylistPitchAccountItemsPlaylistModel>();

  const playlistPitchId = useMemo(() => params.slug, [params]);

  const { playlistPitch, playlistPitchIsLoading } = usePlaylistPitch({
    playlistPitchId,
  });

  const { chartmetricIds } = useTrackIdFromSpotifyId({ spotifyId: playlistPitch?.track.spotifyId });

  const { playlistTrackStats } = usePlaylistTrackStats({
    chartmetricId: (chartmetricIds && chartmetricIds.length && chartmetricIds[0]) ?? undefined,
    fromDate: playlistPitch && format(new Date(playlistPitch.startDate), 'yyyy-MM-dd'),
    toDate: playlistPitch && format(new Date(playlistPitch.endDate), 'yyyy-MM-dd'),
  });

  const countVsReachDataMap = useMemo(() => {
    if (!playlistTrackStats) return;

    return [
      {
        name: 'Count',
        color: 'blue',
        key: 'playlistCount',
        dataMap: new Map(
          playlistTrackStats?.spotify.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.playlistCount])
        ),
      },
      {
        name: 'Reach',
        color: 'pink',
        key: 'playlistReach',
        dataMap: new Map(
          playlistTrackStats?.spotify.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.playlistReach])
        ),
      },
    ];
  }, [playlistTrackStats]);

  const countVsReachChartData = useLineChartData<PlaylistChartMetricsModel>({
    startDate: playlistPitch && format(new Date(playlistPitch.startDate), 'yyyy-MM-dd'),
    endDate: playlistPitch && format(new Date(playlistPitch.endDate), 'yyyy-MM-dd'),
    data: countVsReachDataMap,
  });

  return (
    <div data-testid="campaigns-playlist-pitch-page" className="page-content">
      <UserHeader title="PAGE-TITLES.CAMPAIGNS" />
      {playlist && (
        <PlaylistPitchModal
          open={open}
          trackName={playlistPitch?.track.name}
          artistName={playlistPitch?.artist.name}
          playlist={playlist}
          output={() => {
            setOpen(false);
            setPlaylist(undefined);
          }}
        />
      )}
      <div className="d-flex jc-space-between mt16">
        <div className="d-flex jc-start">
          <Button
            className="ml0 btn-back"
            onClick={() => {
              navigate('/campaigns', { state: 1 });
            }}
          >
            <Icon>chevron_left</Icon>
            <p className="pt2 pl4">{t('COMMON.BACK')}</p>
          </Button>
        </div>
      </div>
      {playlistPitchIsLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {!playlistPitchIsLoading && (
        <div>
          <div className="card mt16 playlist-pitch-title-card">
            <div
              className="pos-abs"
              style={{
                backgroundImage: `url(${playlistPitch?.track.image})`,
                borderRadius: '12px 12px 0px 0px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '-50%',
                height: '180px',
                width: '100%',
              }}
            />
            <div className="d-flex inner-titles flex-wrap gap16">
              <img src={playlistPitch?.track.image} alt="" />
              <div className=" d-flex flex-d-col gap8 mt-auto mb-auto">
                <h3>{playlistPitch?.name}</h3>
                <p className="text-faded">{playlistPitch?.artist.name}</p>
                {playlistPitch && (
                  <p className="text-faded small ">
                    {'Started on ' + format(new Date(playlistPitch?.createdAt), 'dd/MM/yy')}
                  </p>
                )}
                <CampaignStatus status={playlistPitch?.status || ''} />
              </div>
            </div>
            <div className="d-flex gap20 p20 flex-wrap">
              <div className="d-flex flex-w50p w100p-lg-down flex-grow gap20">
                <div className="card-inner flex-grow text-center flex-w50p">
                  <h3>£{playlistPitch?.totalSpend ? playlistPitch?.totalSpend / 100 : 0}</h3>
                  <p className="text-faded mt12">{t('CAMPAIGNS-PAGE.SPENT')}</p>
                </div>
                <div className="card-inner flex-grow text-center flex-w50p">
                  <div className="d-flex jc-center gap8 flex-wrap">
                    <h3>{numberFormatter(playlistPitch?.artist.monthlyListeners ?? 0)}</h3>
                    {playlistPitch && (
                      <PercentageDifference
                        difference={playlistPitch.artist.campaignMonthlyListenersPercentageDifference ?? 0}
                      />
                    )}
                  </div>
                  <p className="text-faded mt12">{t('CAMPAIGNS-PAGE.MONTHLY-LISTENERS')}</p>
                </div>
              </div>
              <div className="d-flex flex-w50p w100p-lg-down flex-grow gap20 ">
                <div className="card-inner flex-grow text-center flex-w50p">
                  <div className="d-flex jc-center gap8 flex-wrap">
                    <h3>{numberFormatter(playlistPitch?.artist.followers ?? 0)}</h3>
                    {playlistPitch && (
                      <PercentageDifference
                        difference={playlistPitch.artist.campaignFollowersPercentageDifference ?? 0}
                      />
                    )}
                  </div>
                  <p className="text-faded mt12">{t('CAMPAIGNS-PAGE.FOLLOWERS')}</p>
                </div>
                <div className="card-inner flex-grow text-center flex-w50p">
                  <h3>{playlistPitch?.playlists.length}</h3>
                  <p className="text-faded mt12">{t('CAMPAIGNS-PAGE.TARGET-PLAYLISTS')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap20 mt20 flex-wrap">
            <div className="card flex-w50p w100p-lg-down h-fit">
              <h4 className="mb20">{t('CAMPAIGNS-PAGE.PLAYLIST-COUNT-VS-REACH')}</h4>
              <LineChartComponent<PlaylistChartMetricsModel> data={countVsReachChartData} />
            </div>
            <div className="card flex-grow flex-w50p w100p-lg-down h-fit">
              <h4 className="mb8">{t('CAMPAIGNS-PAGE.TARGET-PLAYLISTS')}</h4>
              <p className="text-faded small">
                {playlistPitch?.playlists.length} {t('CAMPAIGNS-PAGE.PLAYLISTS-LOWER')}
              </p>
              <p className="text-faded small mb20">{t('CAMPAIGNS-PAGE.TARGET-PLAYLISTS-DESCRIPTION')}</p>
              {playlistPitch?.playlists.map((playlist: PlaylistPitchAccountItemsPlaylistModel, index: number) => (
                <ButtonComponent
                  isCustom
                  onClick={() => {
                    setPlaylist(playlist);
                    setOpen(true);
                  }}
                  key={index}
                  className="w100p mb8 list-item-parent text-left"
                  style={
                    {
                      '--animation-number': `${index}`,
                    } as React.CSSProperties
                  }
                >
                  <div className="d-flex list-item w100p">
                    <object className="playlist-image" data={playlist.image} type="image/png">
                      <img className="playlist-image" src={Images.playlistIcon} alt="Fallback playlist img" />
                    </object>
                    <div className="flex-grow  mt-auto mb-auto">
                      <div className="fs-16 fw-bold text-white">{playlist.name}</div>
                      <p className="text-faded small">{numberFormatter(playlist.stats?.followers) + ' Followers'}</p>
                      <PlaylistPitchStatus status={playlist.status || 0} placedOn={playlist.placedOn} />
                    </div>
                    <Icon className="text-faded">chevron_right</Icon>
                  </div>
                </ButtonComponent>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignPlaylistPitchPage;
